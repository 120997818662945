import { EventTypes, TrackingPort } from '../../ports'
import {
  ConsentDecisionLabelList,
  CookieBannerEvents,
  CookieBannerLabelList,
  CookieBannerMoveEvent,
  PreferenceCenterEvents,
  PreferenceCenterLabelList,
  TrackingLabels,
} from './tracking-labels'
import { FeatureTogglePort, LoggingServicePort } from '../../../shared/ports'
import { ConsentProviderPort } from '../../../onetrust-sdk/ports'
import { useEventTrackingForCbView } from './use-event-tracking-for-cb.view'

const MS_PER_HOUR = 1000 * 60 * 60

export class TrackingService {
  constructor(
    private readonly trackingPort: TrackingPort<TrackingLabels>,
    private readonly consentProvider: ConsentProviderPort,
    private readonly log: LoggingServicePort,
    private readonly featureTogglesPort: FeatureTogglePort
  ) {}

  async trackOTConsentDecisionPageImpression(): Promise<void> {
    await this.trackingPort.track(
      EventTypes.PageImpression,
      await this.createConsentDecisionLabel()
    )
  }

  async trackOTFirstLayerView(): Promise<void> {
    if (useEventTrackingForCbView(this.featureTogglesPort)) {
      await this.trackCookieBannerEvent('view')
    } else {
      await this.trackPageImpressionView()
    }
  }

  async trackOTFirstLayerAgree(): Promise<void> {
    const event = 'agree'
    await this.trackCookieBannerEvent(event)
  }

  async trackOTFirstLayerDisagree(): Promise<void> {
    const event = `disagree`
    await this.trackCookieBannerEvent(event)
  }

  async trackOTFirstLayerSettings(): Promise<void> {
    await this.trackCookieBannerEvent('settings')
  }

  async trackOTFirstLayerScroll(): Promise<void> {
    await this.trackCookieBannerEvent(`read_policy`)
  }

  async trackOTFirstLayerTextLink(link: string): Promise<void> {
    await this.trackCookieBannerMove(`textLink_${link}`)
  }

  async trackOTPreferenceCenterView(): Promise<void> {
    await this.trackPreferenceCenterEvent('view')
  }

  async trackOTPreferenceCenterAgreeAll(): Promise<void> {
    await this.trackPreferenceCenterEvent('agree_All')
  }

  async trackOTPreferenceCenterDisagreeAll(): Promise<void> {
    await this.trackPreferenceCenterEvent('disagree_All')
  }

  async trackOTPreferenceCenterAgreeMySelection(): Promise<void> {
    await this.trackPreferenceCenterEvent('agree_MySelection')
  }

  private async trackPageImpressionView(): Promise<void> {
    const labelList = await this.createCookieBannerFeatureList('view')
    await this.trackingPort.track(EventTypes.PageImpression, labelList)
  }

  private async trackCookieBannerEvent(
    event: CookieBannerEvents
  ): Promise<void> {
    const labelList = await this.createCookieBannerFeatureList(event)
    await this.trackingPort.track(EventTypes.Event, labelList)
  }

  private async trackCookieBannerMove(
    event: CookieBannerMoveEvent
  ): Promise<void> {
    const labelList = await this.createCookieBannerFeatureList(event)
    await this.trackingPort.track(EventTypes.Move, labelList)
  }

  private async createCookieBannerFeatureList(
    event: CookieBannerEvents | CookieBannerMoveEvent
  ): Promise<CookieBannerLabelList> {
    const consentDecisionLabelList = await this.createConsentDecisionLabel()
    return {
      ot_CookieBanner: [event],
      ...consentDecisionLabelList,
    }
  }

  private async trackPreferenceCenterEvent(
    event: PreferenceCenterEvents
  ): Promise<void> {
    const consentDecisionLabelList = await this.createConsentDecisionLabel()
    const featureList: PreferenceCenterLabelList = {
      ot_CookieBannerSettings: [event],
      ...consentDecisionLabelList,
    }
    await this.trackingPort.track(EventTypes.Event, featureList)
  }

  private async createConsentDecisionLabel(): Promise<ConsentDecisionLabelList> {
    const consentDecision = this.consentProvider.hasConsentBeenGiven()
    const consentTtl = await this.consentProvider.getConsentTtl()

    return {
      ot_ConsentDecisionGiven: [consentDecision ? '1' : '0'],
      ...(consentTtl
        ? {
            ot_ConsentTtl: [this.toHoursRounded(consentTtl).toString()],
            ot_ConsentDateOfExpiration: [
              new Date(Date.now() + consentTtl).toISOString().split('T')[0],
            ],
          }
        : {}),
    }
  }

  private toHoursRounded(consentTtl: number): number {
    return Math.round(consentTtl / MS_PER_HOUR)
  }
}
