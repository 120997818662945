import { cookie } from '@otto-ec/global-resources/cookie'
import { Stage } from '../../../shared/ports'

export function setVariantCookie(onetrustVariantId: string, stage: Stage) {
  const expirationIn1Day = new Date()
  expirationIn1Day.setDate(expirationIn1Day.getDate() + 1)

  let domain: string | undefined
  if (!isLocalhost()) {
    domain = stage === 'live' ? 'otto.de' : 'develop.otto.de'
  }

  cookie.set('OTVariant', onetrustVariantId, {
    domain: domain,
    days: 1,
    samesite: 'Lax',
    path: '/',
  })
}

function isLocalhost() {
  return ['localhost', '127.0.0.1'].includes(location.host.split(':')[0])
}
