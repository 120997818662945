import { ElementsEventObserver } from '../elements-event-observer/elements-event-observer'
import { Disconnectable } from '../model/disconnectable'
import { ElementEventCallback } from '../model/element-event-callback'

export class ElementsScrollObserver {
  constructor(private readonly elementsEventObserver: ElementsEventObserver) {}

  observeOnce(
    elementsSelector: string,
    onScrolledCallback: ElementEventCallback<'scroll'>
  ): Disconnectable {
    return this.elementsEventObserver.observeOnce(
      elementsSelector,
      'scroll',
      onScrolledCallback
    )
  }
}
