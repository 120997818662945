import { EnvironmentConfig } from '../../../shared/ports'
import { getOnetrustDataDomainScriptKey } from './get-onetrust-data-domain-script-key'
import { ONETRUST_SDK_INTEGRITY_HASHES } from './onetrust-sdk-integrity-hashes'
import { ConsentProviderConfig } from '../../ports'

export function onetrustConfig(
  config: EnvironmentConfig
): EnvironmentConfig & ConsentProviderConfig {
  const onetrustHash = getOnetrustDataDomainScriptKey(config.stage)

  return {
    ...config,
    onetrust: {
      onetrustSiteId: onetrustHash,
      integrityHashes: ONETRUST_SDK_INTEGRITY_HASHES,
    },
  }
}
