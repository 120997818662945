import {
  ONETRUST_SCRIPT_BASE_PATH_SELF_HOSTED,
  ONETRUST_SCRIPT_BASE_URL,
  ONETRUST_SCRIPT_NAME,
  ONETRUST_SCRIPT_PATH_PRODUCTION_INTERNALLY_HOSTED,
  ONETRUST_SCRIPT_PATH_TEST_INTERNALLY_HOSTED,
} from './onetrust.constants'
import { Stage } from '../../../shared/ports'

export function getOnetrustSdkSrc(
  oneTrustSiteId: string,
  stage: Stage,
  useSelfHosting: boolean | undefined,
  /*                                                          */
  /*                                       */
  baseUrl: string = import.meta.env.BASE_URL
) {
  if (useSelfHosting) {
    const stagePath = stage === 'live' ? '/' : '-develop/'
    const oneTrustScriptPath =
      window.location.hostname === 'localhost'
        ? ONETRUST_SCRIPT_PATH_TEST_INTERNALLY_HOSTED
        : ONETRUST_SCRIPT_PATH_PRODUCTION_INTERNALLY_HOSTED
    return `${baseUrl}${ONETRUST_SCRIPT_BASE_PATH_SELF_HOSTED}${stagePath}${oneTrustScriptPath}${oneTrustSiteId}/${ONETRUST_SCRIPT_NAME}`
  }
  return [ONETRUST_SCRIPT_BASE_URL, oneTrustSiteId, ONETRUST_SCRIPT_NAME].join(
    '/'
  )
}
