import { cookie } from '@otto-ec/global-resources/cookie'
import { Apps, AppsPort } from '../../../ports'

declare global {
  interface Window {
    o_apps: Apps
  }
}

export const appsAdapter: AppsPort = {
  isApp() {
    return cookie.get('app') === 'true'
  },
  apps(): Apps | undefined {
    return window.o_apps ?? undefined
  },
}
