import { MutationObserverFactory } from './mutation-observer-factory'
import { Disconnectable } from '../model/disconnectable'

export class ObserverService {
  constructor(private mutationObserverFactory: MutationObserverFactory) {}

  public registerAndObserve(
    elementToWatch: Node,
    callback: MutationCallback,
    observerConfig: MutationObserverInit = {
      childList: true,
      subtree: true,
      attributes: false,
    }
  ): Disconnectable {
    const mutationObserver = this.mutationObserverFactory.create(callback)
    mutationObserver.observe(elementToWatch, observerConfig)
    return { disconnect: () => mutationObserver.disconnect() }
  }
}
