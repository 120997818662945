/*                                                                                       */
import './hide-banner.scss'
import { ConsentBannerVisibilityPort } from '../../ports'

export const HIDE_OT_CONSENT_BANNER_BASE_CLASS_NAME =
  'eprivacy-ot-cookie-banner--hidden'

export class VisibilityAdapter implements ConsentBannerVisibilityPort {
  private readonly hideConsentBannerClass: string
  constructor(
    private readonly hideConsentBannerPaths: string[],
    options: { isApp: boolean }
  ) {
    this.hideConsentBannerClass =
      HIDE_OT_CONSENT_BANNER_BASE_CLASS_NAME + (options.isApp ? '-app' : '')
  }

  showConsentBanner() {
    window.document.body.classList.remove(this.hideConsentBannerClass)
  }

  hideConsentBanner() {
    window.document.body.classList.add(this.hideConsentBannerClass)
  }

  shouldShowConsentBanner() {
    const pathname = this.normalizePathname(window.location.pathname)
    return !this.hideConsentBannerPaths.some((path) =>
      pathname.endsWith(this.normalizePathname(path))
    )
  }

  private normalizePathname(pathname: string) {
    return pathname.endsWith('/') ? pathname.slice(0, -1) : pathname
  }
}
