/*                                                        */
import { ElementsVisibleObserver } from '../../adapters'
import { TrackingService } from '../tracking-service/tracking-service'

export class PreferenceCenterTracking {
  static readonly PREFERENCE_CENTER_ID = '#onetrust-pc-sdk'
  private isCurrentlyVisible = false

  constructor(
    private readonly elementsVisibleObserver: ElementsVisibleObserver,
    private readonly trackingService: TrackingService
  ) {}

  public registerViewTrackingListener(): void {
    this.elementsVisibleObserver.observe(
      PreferenceCenterTracking.PREFERENCE_CENTER_ID,
      (isVisible) => {
        if (!this.isCurrentlyVisible && isVisible) {
          void this.trackingService.trackOTPreferenceCenterView()
        }
        this.isCurrentlyVisible = isVisible
      }
    )
  }
}
