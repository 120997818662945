/*                            */
/*                                                        */
import {
  ElementEventCallback,
  ElementsClickObserver,
  ElementsScrollObserver,
  ElementsVisibleObserver,
} from '../../adapters'
import { TrackingService } from '../tracking-service/tracking-service'
import { PerformanceTrackingService } from '../performance-tracking-service/performance-tracking-service'

interface ButtonCallbackConfig {
  callback: ElementEventCallback<'click'>
  selector: string
}

export class OnetrustCookieBanner {
  static readonly FIRST_LAYER_SCROLL_CONTAINER_ID = '#onetrust-policy'
  static readonly BANNER_ID = '#onetrust-banner-sdk'
  static readonly BANNER_DIALOG =
    OnetrustCookieBanner.BANNER_ID + ' > div[role="dialog"]'

  constructor(
    private readonly _document: Document,
    private readonly elementClickObserver: ElementsClickObserver,
    private readonly elementsScrollObserver: ElementsScrollObserver,
    private readonly elementsVisibleObserver: ElementsVisibleObserver,
    private readonly trackingService: TrackingService,
    private readonly performanceTrackingService: PerformanceTrackingService
  ) {}

  registerLinkTrackingListener(): void {
    const callback: ElementEventCallback<'click'> = async (
      _element,
      event: Event
    ) => {
      const href = this.getHrefFromEvent(event)
      if (href) {
        event.preventDefault()
        await this.trackAndOpenLink(href)
      }
    }
    this.elementClickObserver.observe('#onetrust-banner-sdk a', callback)
  }

  registerEventListener(): void {
    this.registerElementVisibleEventListener()
    this.registerScrollListener()
    const buttonConfigs: Array<ButtonCallbackConfig> = [
      {
        selector: '#onetrust-accept-btn-handler',
        callback: async () => {
          await this.trackingService.trackOTFirstLayerAgree()
        },
      },
      {
        selector: '#onetrust-reject-all-handler',
        callback: async () => {
          await this.trackingService.trackOTFirstLayerDisagree()
        },
      },
      {
        selector: '#onetrust-pc-btn-handler',
        callback: async () => {
          await this.trackingService.trackOTFirstLayerSettings()
        },
      },
      {
        selector: '#accept-recommended-btn-handler',
        callback: async () => {
          await this.trackingService.trackOTPreferenceCenterAgreeAll()
        },
      },
      {
        selector: '.ot-pc-refuse-all-handler',
        callback: async () => {
          await this.trackingService.trackOTPreferenceCenterDisagreeAll()
        },
      },
      {
        selector: '.save-preference-btn-handler',
        callback: async () => {
          await this.trackingService.trackOTPreferenceCenterAgreeMySelection()
        },
      },
    ]

    this.registerButtonAndLinkEventListeners(buttonConfigs)
  }

  private async trackAndOpenLink(href: string) {
    const openLink = () => (this._document.location.href = href)
    /*                                                         */
    setTimeout(openLink, 100)
    await this.trackingService.trackOTFirstLayerTextLink(
      this.extractPathForTracking(href)
    )
  }

  private getHrefFromEvent(event: Event) {
    if (event.currentTarget instanceof HTMLAnchorElement) {
      event.preventDefault()
      const element = event.currentTarget
      return element.href
    }
    return null
  }

  private extractPathForTracking(href: string): string {
    const url = new URL(href)
    return url.pathname.replace(/^\/|\/$/g, '')
  }

  private registerButtonAndLinkEventListeners(
    buttonConfigs: Array<ButtonCallbackConfig>
  ): void {
    buttonConfigs.forEach((config) => {
      this.elementClickObserver.observe(config.selector, config.callback)
    })
  }

  private registerScrollListener(): void {
    this.elementsScrollObserver.observeOnce(
      OnetrustCookieBanner.FIRST_LAYER_SCROLL_CONTAINER_ID,
      () => this.trackingService.trackOTFirstLayerScroll()
    )
  }

  private registerElementVisibleEventListener(): void {
    this.elementsVisibleObserver.observeOnce(
      OnetrustCookieBanner.BANNER_ID,
      () => {
        void this.trackingService.trackOTFirstLayerView()
        this.performanceTrackingService.finishOTBannerLoadTimeMeasurement()
        this.setBannerDialogFocus()
      }
    )
  }

  private setBannerDialogFocus() {
    const bannerDialogElement = this._document.querySelector<HTMLElement>(
      OnetrustCookieBanner.BANNER_DIALOG
    )
    if (bannerDialogElement) {
      bannerDialogElement.setAttribute('tabindex', '-1')
      bannerDialogElement.focus()
    }
  }
}
