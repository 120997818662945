import { ConsentProviderPort } from '../../ports'
import { CmpImpl } from '../../../shared/ports'

export async function informClientOnConsentChanged(
  consentProvider: ConsentProviderPort,
  cmp: CmpImpl
): Promise<void> {
  try {
    await cmp.archiveReceipt(consentProvider.getConsentId(), 'OneTrust-CB-Web')
    await cmp.emitConsentChangeEvent()
  } catch {
    await cmp.emitConsentChangeEvent()
  }
}
