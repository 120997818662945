import { LoggingServicePort } from '../../../shared/ports'

declare global {
  type CookieStoreGetReturnType = {
    name: string
    value: string
    expires?: number
  } | null

  interface CookieStore {
    get(name: string): Promise<CookieStoreGetReturnType>
  }

  const cookieStore: CookieStore
}

export class ConsentTtlService {
  constructor(private readonly logger: LoggingServicePort) {}

  /**
 *
 */
  async getConsentTtl(): Promise<number | undefined> {
    if ('cookieStore' in window) {
      return await this.getExpiresDateWithCookieStore()
    }
    return undefined
  }

  private async getExpiresDateWithCookieStore(): Promise<number | undefined> {
    try {
      const cookie = await cookieStore.get('OptanonAlertBoxClosed')

      if (!cookie) {
        return undefined
      }

      if (typeof cookie.expires !== 'number') {
        /*                                  */
        /*                                                           */
        /*                                 */
        return undefined
      }

      const ttl = cookie.expires - Date.now()
      if (ttl < 0) {
        this.logger.logError(
          `Cookie OptanonAlertBoxClosed has an invalid expiration time: ${cookie.expires}, resulting in ttl: ${ttl}`
        )
        return undefined
      }
      return ttl
    } catch (error) {
      this.logger.logError('can not get consentTtl', error)
      return undefined
    }
  }
}
