import { domainScript } from '../../../../../../onetrust-hashes.config.json'
import { Stage } from '../../../shared/ports'

export function getOnetrustDataDomainScriptKey(stage: Stage): string {
  const domainScriptKey =
    stage === 'live' ? domainScript.live : domainScript.test

  if (domainScriptKey.endsWith('-test')) {
    return domainScriptKey
  }

  const loadPreviewScript =
    location.href?.includes('otpreview=true') ||
    location.href?.includes('localhost') ||
    location.href?.includes('127.0.0.1')
  return domainScriptKey + (loadPreviewScript ? '-test' : '')
}
