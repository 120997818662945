import { ActiveOnexAssignmentPort, OnexesDto } from '../../ports'

const GET_ASSIGNMENT_PATH = '/onex/assignments/'

export const activeOnexAssignmentAdapter: ActiveOnexAssignmentPort =
  async () => {
    const response = await fetch(GET_ASSIGNMENT_PATH)

    if (!response.ok) {
      throw new Error(
        `Failed to fetch active onex assignments: ${response.status}:${response.statusText}`
      )
    }

    return (await response.json()) as OnexesDto
  }
