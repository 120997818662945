import { ElementsExistsObserver } from '../elements-exists-observer/elements-exists-observer'
import { ElementEventCallback } from '../model/element-event-callback'
import { Disconnectable } from '../model/disconnectable'
import { ElementExistsCallback } from '../model/element-exists-callback'

export class ElementsEventObserver {
  constructor(
    private _document: Document,
    private readonly elementsExistsObserver: ElementsExistsObserver,
    private abortController: AbortController = new AbortController()
  ) {}

  observeOnce<K extends keyof HTMLElementEventMap>(
    elementsSelector: string,
    event: K,
    eventCallback: ElementEventCallback<K>
  ): Disconnectable {
    return this.observe(elementsSelector, event, eventCallback, { once: true })
  }

  observe<K extends keyof HTMLElementEventMap>(
    elementsSelector: string,
    event: K,
    eventCallback: ElementEventCallback<K>,
    options: { once: boolean } = { once: false }
  ): Disconnectable {
    const disconnect = this.elementsExistsObserver.observeOnce(
      elementsSelector,
      this.createCallback<K>(event, eventCallback, {
        ...options,
        signal: this.abortController.signal,
      })
    )
    return {
      disconnect: () => {
        this.abortController.abort('Abort by call to disconnect')
        disconnect.disconnect()
      },
    }
  }

  private createCallback<K extends keyof HTMLElementEventMap>(
    event: K,
    eventCallback: ElementEventCallback<K>,
    options?: AddEventListenerOptions
  ): ElementExistsCallback {
    return (element: HTMLElement) => {
      element.addEventListener(
        event,
        (event) => void eventCallback(element, event),
        { ...options }
      )
    }
  }
}
