import { logger } from '../shared'
import { bootstrap } from './bootstrap'
import { ConsentProviderPort } from './ports'

export const consentProvider: Promise<ConsentProviderPort | undefined | void> =
  bootstrap().catch((error) => {
    logger.logError(
      new Error(`Failed to bootstrap onetrust-sdk`, {
        cause: error,
      })
    )
  })
