import {
  activeOnexAssignmentAdapter,
  ActiveOnexConfigAdapter,
  appsAdapter,
  CmpAdapter,
  environmentConfigAdapter,
  featureTogglesAssetsAdapter,
  oScaleAdapter,
} from './adapters'
import { AppsPort, CmpImpl, FeatureTogglePort, OScaleUserTiming } from './ports'

import type { GlobalOnexConfig, OnexConfig } from '@onex/model'
import { logger } from './logger'

export const environmentConfig = environmentConfigAdapter()
export const featureToggles: FeatureTogglePort = featureTogglesAssetsAdapter
/*                                                           */
export const cmp: CmpImpl = new CmpAdapter().cmp()
export const apps: AppsPort = appsAdapter
/*                                                                       */
export const oScale: OScaleUserTiming = oScaleAdapter.userTiming()

export const activeVariantConfig: Promise<OnexConfig> = loadActiveOnexConfig()

async function loadActiveOnexConfig(): Promise<OnexConfig> {
  const activeOnex = new ActiveOnexConfigAdapter(
    logger,
    activeOnexAssignmentAdapter,
    await loadOnexConfig()
  )
  return activeOnex.activeOnexConfig()
}

async function loadOnexConfig(): Promise<GlobalOnexConfig> {
  return (
    environmentConfig.stage === 'live'
      ? /*                                                        */
        await import('@onex/config')
      : /*                                                        */
        await import('@onex/config-nonlive')
  ).config
}
