import { LoggingServicePort } from '../../ports'
import { sendCustomError } from '@otto-ec/global-resources/apm'

/**
 *
 */
export class LoggingServiceGlobalResourcesAdapter
  implements LoggingServicePort
{
  private static readonly NAMESPACE_CONSENT_BANNER = 'eprivacy_consentBanner'

  /**
 *
 *
 *
 *
 *
 */
  logMessageWithId(id: string, ...messages: unknown[]) {
    this.logMessages(id, messages)
  }

  /**
 *
 *
 *
 */
  logMessage(...messages: unknown[]) {
    this.logMessages(null, messages)
  }

  logError(...errors: unknown[]) {
    const message = this.joinToString(errors)

    if (!message || message.length === 0) {
      return
    }
    const trimmedMessage = this.trimMessage(message)
    const data = {
      ...(trimmedMessage ? { message: trimmedMessage } : {}),
      ...(errors.length === 1 && errors[0] instanceof Error
        ? { stack: this.trimMessage(errors[0].stack, 256) }
        : {}),
      ...(errors.length === 1 && errors[0] instanceof Error
        ? { errorName: this.trimMessage(errors[0].name, 256) }
        : {}),
    }
    void sendCustomError(
      LoggingServiceGlobalResourcesAdapter.NAMESPACE_CONSENT_BANNER,
      data
    )
  }

  private joinToString(data: unknown[]) {
    return data
      .map((arg) => {
        if (typeof arg === 'string') {
          return arg
        }
        if (arg instanceof Error) {
          return arg.message
        }
        try {
          return JSON.stringify(arg)
        } catch {
          return String(arg)
        }
      })
      .join(' ')
  }

  private trimMessage(message?: string, numChars: number = 128) {
    return message?.substring(0, numChars).trim()
  }

  private logMessages(id: string | null, messages: unknown[]) {
    const joinedMessage = this.joinToString(messages)
    const trimmedMessage = this.trimMessage(joinedMessage)
    if (!trimmedMessage || trimmedMessage.length === 0) {
      return
    }
    const data = {
      message: trimmedMessage,
      ...(id ? { id: this.trimMessage(id) } : {}),
    }
    void sendCustomError(
      LoggingServiceGlobalResourcesAdapter.NAMESPACE_CONSENT_BANNER,
      data
    )
  }
}
