import {
  activeVariantConfig,
  cmp,
  environmentConfig,
  featureToggles,
  logger,
  showConsentBannerUseCase,
} from '../shared'
import { retryAdapter } from '../shared/adapters'
import { loadOneTrustConsentProviderFactory, onetrustConfig } from './adapters'
import { ConsentProviderPort } from './ports'
import {
  informClientOnConsentChanged,
  isOneTrustEnabled,
  isOneTrustInternalHostingEnabled,
} from './core'

export async function bootstrap(): Promise<ConsentProviderPort | undefined> {
  showConsentBannerUseCase.hideInitial()

  if (!isOneTrustEnabled(featureToggles)) {
    return
  }
  const variantConfig = await activeVariantConfig
  const internalHosting: boolean =
    isOneTrustInternalHostingEnabled(featureToggles)
  const loadOnetrustConsentProvider = loadOneTrustConsentProviderFactory(
    logger,
    environmentConfig,
    internalHosting,
    retryAdapter
  )
  const consentProvider = await loadOnetrustConsentProvider(
    onetrustConfig(environmentConfig),
    variantConfig.onetrustVariantId
  )

  /*                                                              */
  consentProvider.OnConsentChanged(async () => {
    await informClientOnOnetrustConsentChange(consentProvider)
  })
  return consentProvider
}

async function informClientOnOnetrustConsentChange(
  consentProvider: ConsentProviderPort
) {
  try {
    await informClientOnConsentChanged(consentProvider, cmp)
  } catch (error) {
    logger.logError(
      new Error(`Could not inform client on consent change`, { cause: error })
    )
  }
}
