import {
  ElementsClickObserver,
  ElementsEventObserver,
  ElementsExistsObserver,
  ElementsScrollObserver,
  ElementsVisibleObserver,
  MutationObserverFactory,
  ObserverService,
  TrackingBctAdapter,
} from './adapters'
import {
  OnetrustCookieBanner,
  PerformanceTrackingService,
  PreferenceCenterTracking,
  TrackingLabels,
  TrackingService,
} from './core'
import {
  apps,
  featureToggles,
  logger,
  oScale,
  showConsentBannerUseCase,
} from '../shared'
import { consentProvider } from '../onetrust-sdk'

declare global {
  /*                                 */
  interface Window {
    cookieBanner: OnetrustCookieBanner
    o_cookieBanner: OnetrustCookieBanner
  }
}
export const bootstrap = async () => {
  const performanceTrackingService = new PerformanceTrackingService(
    logger,
    oScale,
    performance,
    0.001
  )
  performanceTrackingService.startOTBannerLoadTimeMeasurement()

  const consentProviderAwaited = await consentProvider
  if (!consentProviderAwaited) {
    return
  }
  const mutationObserverFactory: MutationObserverFactory = {
    create: (callback: MutationCallback) => new MutationObserver(callback),
  }
  const observerService = new ObserverService(mutationObserverFactory)

  const elementsExistsObserver = new ElementsExistsObserver(
    document,
    observerService
  )
  const elementsEventObserver = new ElementsEventObserver(
    document,
    elementsExistsObserver
  )
  const elementsClickObserver = new ElementsClickObserver(elementsEventObserver)
  const elementsScrollObserver = new ElementsScrollObserver(
    elementsEventObserver
  )
  const elementsVisibleObserver = new ElementsVisibleObserver(
    elementsExistsObserver
  )

  const trackingBctAdapter = new TrackingBctAdapter<TrackingLabels>(logger)
  const trackingService = new TrackingService(
    trackingBctAdapter,
    consentProviderAwaited,
    logger,
    featureToggles
  )

  if (!apps.isApp()) {
    /*                                                           */
    void trackingService.trackOTConsentDecisionPageImpression()
  }

  const pcTracking = new PreferenceCenterTracking(
    elementsVisibleObserver,
    trackingService
  )
  pcTracking.registerViewTrackingListener()

  const onetrustCookieBanner = new OnetrustCookieBanner(
    document,
    elementsClickObserver,
    elementsScrollObserver,
    elementsVisibleObserver,
    trackingService,
    performanceTrackingService
  )

  onetrustCookieBanner.registerEventListener()
  onetrustCookieBanner.registerLinkTrackingListener()

  await showConsentBannerUseCase.run()
}
