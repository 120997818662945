import { cookie } from '@otto-ec/global-resources/cookie'

export function getConsentId() {
  const optanonConsentCookie = cookie.get('OptanonConsent')
  if (!optanonConsentCookie) {
    console.warn('OptanonConsent cookie not found')
    return null
  }
  const cookieValuesArray: string[] = optanonConsentCookie.split('&')
  const targetValueArray = cookieValuesArray.find((element) =>
    element.startsWith('consentId=')
  )
  if (!targetValueArray) {
    console.warn(`'consentId' not found in OptanonConsent cookie`)
    return null
  }
  return decodeURIComponent(targetValueArray.split('=')[1])
}
