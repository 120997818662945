import { LoggingServicePort, OScaleUserTiming } from '../../../shared/ports'

export class PerformanceTrackingService {
  private readonly metricNameCbLoadingTime = 'rum_consent_banner_loading_time'
  private readonly metricNameCbInit = 'consent_banner_init'
  private readonly metricNameCbLoaded = 'consent_banner_loaded'
  private readonly invalidMetricValue = -1

  constructor(
    private readonly loggingService: LoggingServicePort,
    private readonly oScale: OScaleUserTiming,
    private readonly performance: Performance,
    private readonly logFraction: number = 0.001
  ) {}

  startOTBannerLoadTimeMeasurement(): void {
    this.trackPerformanceMetric(this.metricNameCbInit)
  }

  finishOTBannerLoadTimeMeasurement(): void {
    this.trackPerformanceMetric(this.metricNameCbLoaded)

    const calculatedPerformanceTime = this.calculatePerformanceTime(
      this.metricNameCbInit,
      this.metricNameCbLoaded
    )

    this.logPerformanceFractionated(calculatedPerformanceTime)

    this.sendPerformanceMetric(
      this.metricNameCbLoadingTime,
      calculatedPerformanceTime
    )
  }

  private logPerformanceFractionated(calculatedPerformanceTime: number) {
    if (Math.random() <= this.logFraction) {
      this.loggingService.logMessage(
        /*                              */
        `Consent banner loaded in ${Math.round(calculatedPerformanceTime)} ms`
      )
    }
  }

  private trackPerformanceMetric(performanceMetric: string): void {
    this.performance.mark(performanceMetric)
  }

  private calculatePerformanceTime(
    startMetric: string,
    endMetric: string
  ): number {
    try {
      const start = this.performance.getEntriesByName(startMetric)[0]?.startTime
      const end = this.performance.getEntriesByName(endMetric)[0]?.startTime

      if (typeof start === 'undefined' || typeof end === 'undefined') {
        return this.invalidMetricValue
      }

      return Math.round(end - start)
    } catch (error) {
      this.loggingService.logError(
        new Error(`Error calculating performance time:`, { cause: error })
      )
      return this.invalidMetricValue
    }
  }

  private sendPerformanceMetric(
    performanceMetricName: string,
    performanceMetricValue: number
  ): void {
    if (performanceMetricValue === this.invalidMetricValue) {
      this.loggingService.logError(
        new Error(
          'Unable to send performance metric: invalid performance metric value'
        )
      )
      return
    }
    try {
      this.performance.mark(performanceMetricName)
      this.oScale.sendBeacon({
        [performanceMetricName]: performanceMetricValue,
      })
    } catch (error) {
      this.loggingService.logError(
        new Error(`Error sending performance metric:`, { cause: error })
      )
    }
  }
}
