import { RetryPort } from '../../ports'

export const retryAdapter: RetryPort = {
  run: async <T>(
    fn: () => Promise<T> | T,
    attempts: number,
    logFailedAttempt: (n: number, error: unknown) => void = () => {}
  ): Promise<T> => {
    let lastError: unknown
    for (let n = 1; n <= attempts; n++) {
      try {
        return await fn()
      } catch (error) {
        lastError = new Error(`Failed attempt number: ${n}/${attempts}`, {
          cause: error,
        })
        logFailedAttempt(n, error)
      }
    }
    throw new Error(
      `Failed to execute function after max attempts of ${attempts}.`,
      {
        cause: lastError!,
      }
    )
  },
}
