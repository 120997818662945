import { ElementsEventObserver } from '../elements-event-observer/elements-event-observer'
import { Disconnectable } from '../model/disconnectable'
import { ElementEventCallback } from '../model/element-event-callback'

export class ElementsClickObserver {
  constructor(private readonly elementsEventObserver: ElementsEventObserver) {}

  observe(
    elementsSelector: string,
    onClickCallback: ElementEventCallback<'click'>
  ): Disconnectable {
    return this.elementsEventObserver.observe(
      elementsSelector,
      'click',
      onClickCallback
    )
  }
}
