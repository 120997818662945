import {
  ActiveOnexAssignmentPort,
  ActiveOnexConfigPort,
  LoggingServicePort,
  OnexesDto,
} from '../../ports'
import {
  GlobalOnexConfig,
  OnexConfig,
  OnexId,
  OnexVariantNames,
} from '@onex/model'

/**
 *
 */
export class ActiveOnexConfigAdapter implements ActiveOnexConfigPort {
  constructor(
    private readonly logger: LoggingServicePort,
    private readonly activeOnexAssigmentPort: ActiveOnexAssignmentPort,
    private readonly globalOnexConfig: GlobalOnexConfig
  ) {}

  /**
 *
 *
 */
  async activeOnexConfig(): Promise<OnexConfig> {
    const statusQuo = this.globalOnexConfig.activeOnexConfig.StatusQuo
    try {
      if (!this.globalOnexConfig.active) {
        return statusQuo
      }

      const activeOnexes = await this.activeOnexAssigmentPort()
      const activeOnexVariantName = this.getActiveOnexVariant(activeOnexes)
      if (!activeOnexVariantName) {
        return statusQuo
      }

      return this.findConfig(activeOnexVariantName)
    } catch (error) {
      this.logger.logError(
        new Error(`Could not fetch active onex assignments`, { cause: error })
      )
      return statusQuo
    }
  }

  defaultConfig(): OnexConfig {
    const statusQuoConfig = this.findConfig('StatusQuo')
    if (statusQuoConfig) {
      return statusQuoConfig
    }
    throw new Error('No StatusQuo config is needed')
  }

  private getActiveOnexId(): OnexId {
    return this.globalOnexConfig.id
  }

  private getActiveOnexVariant({ onexes }: OnexesDto): OnexVariantNames | null {
    const activeOnexId = this.getActiveOnexId()
    const assignedOnex = onexes.find(({ n }) => n === activeOnexId)
    if (!assignedOnex) {
      return null
    }
    return assignedOnex.v as OnexVariantNames
  }

  private findConfig(variant: OnexVariantNames): OnexConfig {
    return {
      ...this.globalOnexConfig.activeOnexConfig.StatusQuo,
      ...this.globalOnexConfig.activeOnexConfig[variant],
    }
  }
}
