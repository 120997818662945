import { ElementsExistsObserver } from '../elements-exists-observer/elements-exists-observer'
import { Disconnectable } from '../model/disconnectable'

export class ElementsVisibleObserver {
  constructor(private readonly elementExistsObserver: ElementsExistsObserver) {}

  /**
 *
 *
 *
 */
  observe(
    elementSelector: string,
    isVisibleCallback: (isVisible: boolean, observation: Disconnectable) => void
  ): Disconnectable {
    let io: IntersectionObserver
    const disco = this.elementExistsObserver.observeOnce(
      elementSelector,
      (element: Element, disconnect) => {
        const callback = this.createMutationCallback(
          isVisibleCallback,
          disconnect
        )
        io = new IntersectionObserver(callback)
        io.observe(element)
      }
    )
    return {
      disconnect: () => {
        disco.disconnect()
        io?.disconnect()
      },
    }
  }

  observeOnce(
    elementSelector: string,
    isVisibleCallback: (observation: Disconnectable) => void | Promise<void>
  ): Disconnectable {
    return this.observe(elementSelector, (isVisible, disconnect) => {
      if (isVisible) {
        void isVisibleCallback(disconnect)
        disconnect.disconnect()
      }
    })
  }

  private createMutationCallback(
    visibleCallback: (
      isVisible: boolean,
      observation: Disconnectable
    ) => void | Promise<void>,
    { disconnect }: Disconnectable
  ): IntersectionObserverCallback {
    return (
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) => {
      void visibleCallback(entries[0]?.isIntersecting ?? false, {
        disconnect: () => {
          observer.disconnect()
          disconnect()
        },
      })
    }
  }
}
