import { OScalePort, OScaleUserTiming } from '../../../ports'

declare global {
  interface Window {
    o_scale?: {
      userTiming?: {
        sendBeacon: (metric: Record<string, number>) => void
      }
    }
  }
}

export const oScaleAdapter: OScalePort = {
  userTiming(): OScaleUserTiming {
    return {
      sendBeacon(metric: Record<string, number>): void {
        const sendBeacon = window.o_scale?.userTiming?.sendBeacon
        if (sendBeacon) {
          sendBeacon(metric)
        }
      },
    }
  },
}
