import { ObserverService } from '../observer-service/observer-service'
import { ElementExistsCallback } from '../model/element-exists-callback'
import { Disconnectable } from '../model/disconnectable'
import { noop } from '../model/noop'

export class ElementsExistsObserver {
  constructor(
    private readonly _document: Document,
    private readonly observerService: ObserverService
  ) {}

  observeOnce(
    selector: string,
    existsCallback: ElementExistsCallback
  ): Disconnectable {
    const noopDisconnect = { disconnect: noop }
    if (
      this.queryElementAndCallCallback(selector, existsCallback, noopDisconnect)
    ) {
      return noopDisconnect
    }
    const callback = this.createMutationCallback(selector, existsCallback)
    return this.observerService.registerAndObserve(
      this._document.body,
      callback,
      {
        attributes: false,
        subtree: true,
        childList: true,
      }
    )
  }

  private queryElementAndCallCallback(
    selector: string,
    existsCallback: ElementExistsCallback,
    disconnect: Disconnectable
  ) {
    const element = this._document.querySelectorAll<HTMLElement>(selector)
    if (element.length) {
      element.forEach((element) => existsCallback(element, disconnect))
      return true
    }
    return false
  }

  private createMutationCallback(
    selector: string,
    existsCallback: ElementExistsCallback
  ): MutationCallback {
    return (mutationsList: MutationRecord[], observer: Disconnectable) => {
      if (
        this.queryElementAndCallCallback(selector, existsCallback, observer)
      ) {
        observer.disconnect()
      }
    }
  }
}
