import { ConsentProviderConfig } from '../../ports'

export async function loadOnetrustSdk(
  { onetrust: { onetrustSiteId, integrityHashes } }: ConsentProviderConfig,
  srcUrl: string,
  checkIntegrityHashes = true
): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const scriptElement = document.createElement('script')

    scriptElement.setAttribute('data-domain-script', onetrustSiteId)
    scriptElement.src = srcUrl
    if (checkIntegrityHashes) {
      scriptElement.integrity = integrityHashes.join(' ')
    }

    scriptElement.type = 'text/javascript'
    scriptElement.async = true
    scriptElement.crossOrigin = 'anonymous'

    scriptElement.onload = () => resolve()
    scriptElement.onerror = (error) => {
      scriptElement.remove()
      const errorMessage = `Failed to load OneTrust SDK from ${srcUrl}`
      reject(new Error(errorMessage, { cause: error }))
    }

    document.head.appendChild(scriptElement)
  })
}
